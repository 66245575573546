define("soofa-buzz-client/helpers/split-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.splitString = splitString;
  const {
    Helper: {
      helper
    }
  } = Ember;
  function splitString(str) {
    let splitString = str[0].split(',');
    return splitString;
  }
  var _default = _exports.default = helper(splitString);
});