define("soofa-buzz-client/confirmation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lRsfZVn9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-column layout-align-center-center\"],[9],[0,\"\\n  Your response has been submitted\\n\"],[4,\"paper-button\",null,[[\"primary\",\"raised\",\"onClick\"],[true,true,[27,\"action\",[[22,0,[]],[27,\"transition-to\",[\"login\"],null]],null]]],{\"statements\":[[0,\"    Login\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-buzz-client/confirmation/template.hbs"
    }
  });
});