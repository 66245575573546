define("soofa-buzz-client/application/controller", ["exports", "soofa-client-shared/mixins/has-me", "soofa-buzz-client/config/environment"], function (_exports, _hasMe, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Controller
  } = Ember;
  var _default = _exports.default = Controller.extend(_hasMe.default, {
    // computed
    noNav: Ember.computed('currentRouteName', function () {
      return [].includes(this.get('currentRouteName'));
    }),
    isStaging: Ember.computed('currentRouteName', function () {
      return _environment.default.environment != 'production';
    }),
    noContainer: Ember.computed('currentRouteName', function () {
      return ['questionnaire'].includes(this.get('currentRouteName'));
    }),
    init() {
      Ember.$(window).scrollTop();
      let zero = 0;
      Ember.$(window).on('scroll', function () {
        if (zero >= 60) {
          Ember.$('header').toggleClass('hide-header', Ember.$(window).scrollTop() > zero);
          Ember.$('.top-buttons').toggleClass('hide-header', Ember.$(window).scrollTop() > zero);
        }
        zero = Ember.$(window).scrollTop();
      });
    }
  });
});