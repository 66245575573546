define("soofa-buzz-client/index/route", ["exports", "soofa-client-shared/mixins/has-me"], function (_exports, _hasMe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_hasMe.default, {
    beforeModel() {
      return this.transitionTo('source', {
        queryParams: {
          destination: 'location',
          referrer: 'direct'
        }
      });
    }
  });
});