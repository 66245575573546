define("soofa-buzz-client/location/confirm/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    hash
  } = Ember.RSVP;
  var _default = _exports.default = Ember.Route.extend({
    // services
    scriptr: Ember.inject.service(),
    store: Ember.inject.service(),
    // events
    afterModel(model) {
      // get poll and return hash
      let poll = model.get('poll');
      this.set('poll', poll);
      return hash(poll);
    },
    setupController(controller, model) {
      // init
      this._super(controller, model);
      let poll = this.get('poll');

      // reset attrs
      controller.setProperties({
        answer: null,
        answerQuestion: false,
        error: false,
        isSent: false,
        poll
      });
    },
    // actions
    actions: {
      async sendAnswer(context, location) {
        // init
        let scriptr = this.get('scriptr');
        let meService = this.get('meService');
        let user = this.get('meService.model');

        // getting original poll from controller as we don't want it to
        // get the poll from the location again as it might have changed (template override might not be there)
        let poll = context.get('poll');

        // get referralId
        let referralId = '';
        if (user && user.get('referralId')) {
          referralId = user.get('referralId');
        } else if (meService && meService.get('referralId')) {
          referralId = meService.get('referralId');
        } else {
          referralId = 'No referralId';
        }

        // create submission
        let attributes = {
          active: false,
          content: context.answer,
          phoneNumber: 'soofa-buzz',
          timestamp: new Date().valueOf(),
          location: location.get('id'),
          poll: poll.get('id'),
          referralId,
          user: user ? user.get('id') : null
        };

        // hit endpoint and create the submission
        scriptr.post('/modelManager', {
          data: {
            modelName: 'submission',
            attributes
          }
        }).then(({
          response
        }) => {
          if (response.metadata.status == 'success') {
            this.get('store').findRecord('submission', response.metadata.modelId).then(submission => {
              meService.set('submission', submission);
            });
            context.setProperties({
              isSent: true,
              error: false
            });
          } else {
            context.setProperties({
              isSent: true,
              error: true
            });
          }
        }).catch(function () {
          context.setProperties({
            isSent: true,
            error: true
          });
        });
      },
      handleExplore(url) {
        // go to url
        window.location.href = url;
      }
    }
  });
});