define("soofa-buzz-client/not-supported/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "e7+TONMQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-column layout-wrap box-white poll-question\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex-100 layout-row layout-align-center-center\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"message\"],[9],[0,\"Hi! Soofa Buzz must be used on a mobile device. Please try this again on your phone.\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-buzz-client/not-supported/template.hbs"
    }
  });
});