define("soofa-buzz-client/content-scan/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    // params

    queryParams: {
      q: {
        refreshModel: true
      }
    },
    scriptr: Ember.inject.service(),
    store: Ember.inject.service(),
    keen: Ember.inject.service(),
    /*
    https://soofabuzz.com/content-scan/?-MqpTzvxmGfHDKXvIvqI&-Mf3WtNYm8Ko5SCmsDXP
    */
    async setupController(controller, model) {
      this._super(controller, model);
      let rawUrlStr = controller.get('q');
      let store = this.get('store');
      let replacementOptions = ['soofaAdId', 'soofaLocationId', 'soofaLocationName'];
      try {
        let urlDecoded = atob(rawUrlStr);
        let varArr = urlDecoded.split('&');
        let [locationId, adId, test] = varArr;
        let testing = false;
        if (test == 't') {
          testing = true;
        }
        let ad = await store.findRecord('ad', adId);
        let locationModel = await store.findRecord('location', locationId);
        let redirectUrl = await ad.get('qrRedirectUrl');
        let locationName = locationModel.get('locationName');
        if (redirectUrl) {
          replacementOptions.forEach(replacementString => {
            let replacementStringFormatted = `{{${replacementString}}}`;
            let includesReplacement = redirectUrl.includes(replacementStringFormatted);
            if (includesReplacement) {
              let valueToInsert = null;
              // If we here, the redirectUrl contains the replacementString
              switch (replacementString) {
                case 'soofaAdId':
                  valueToInsert = adId;
                  break;
                case 'soofaLocationId':
                  valueToInsert = locationId;
                  break;
                case 'soofaLocationName':
                  {
                    valueToInsert = btoa(locationName);
                    break;
                  }
                default:
                // code block
              }
              redirectUrl = redirectUrl.replace(replacementStringFormatted, valueToInsert);
            }
          });
          this.get('keen').recordEvent('contentScanned', {
            locationId,
            adId,
            rawUrlStr,
            testing,
            redirectUrl
          });
          await this.get('scriptr').request(`contentScanFinished?locationId=${locationId}&adId=${adId}&rawStr=${rawUrlStr}&test=${testing}`);
          window.location.replace(redirectUrl);
        } else {
          // Shouldn't be possible unless someone took a picture of an ad with a QR code and then the QR code was removed later, and then they scan it.
          await this.get('scriptr').request(`contentScanFinished?rawStr=${rawUrlStr}&error=NoRedirectUrl`);
          window.location.replace(redirectUrl);
        }
      } catch (e) {
        // TODO: Maybe make a "something went wrong" page?
        await this.get('scriptr').request(`contentScanFinished?rawStr=${rawUrlStr}&error=${e}`);
        window.location.replace('https://soofadigital.com/');
      }
    }
  });
});