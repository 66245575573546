define("soofa-buzz-client/helpers/meters-to-pixels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.metersToPixels = metersToPixels;
  const {
    Helper: {
      helper
    }
  } = Ember;
  function metersToPixels(params) {
    let [meters, latitude] = params;
    return meters / 0.075 / Math.cos(latitude * Math.PI / 180);
  }
  var _default = _exports.default = helper(metersToPixels);
});