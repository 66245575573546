define("soofa-buzz-client/router", ["exports", "soofa-buzz-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Router
  } = Ember;
  let router = Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  router.map(function () {
    this.route('location', function () {
      this.route('confirm', {
        path: 'confirm/:location_id'
      });
    });
    this.route('confirmation');
    this.route('content-scan');
    this.route('event-scan');
    this.route('fb-scan');
    this.route('login');
    this.route('not-supported');
    this.route('password');
    this.route('signup');
    this.route('source');
  });
  var _default = _exports.default = router;
});