define("soofa-buzz-client/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    // params
    queryParams: {
      destination: {
        refreshModel: true
      }
    },
    // events
    beforeModel() {
      if (this.get('session').get('isAuthenticated')) {
        return this.transitionTo('location');
      }
    }
  });
});