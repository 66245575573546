define("soofa-buzz-client/initializers/initialize-torii-callback", ["exports", "soofa-buzz-client/config/environment", "torii/redirect-handler"], function (_exports, _environment, _redirectHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'torii-callback',
    before: 'torii',
    initialize: function (application) {
      if (arguments[1]) {
        // Ember < 2.1
        application = arguments[1];
      }
      if (_environment.default.torii && _environment.default.torii.disableRedirectInitializer) {
        return;
      }
      application.deferReadiness();
      _redirectHandler.default.handle(window).catch(function () {
        application.advanceReadiness();
      });
    }
  };
});