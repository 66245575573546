define("soofa-buzz-client/event-scan/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    // params

    queryParams: {
      q: {
        refreshModel: true
      },
      location: {
        refreshModel: true
      }
    },
    scriptr: Ember.inject.service(),
    store: Ember.inject.service(),
    keen: Ember.inject.service(),
    async setupController(controller, model) {
      this._super(controller, model);
      let store = this.get('store');
      let eventUrl = controller.get('q');
      let locationId = atob(controller.get('location'));
      let locationModel = await store.findRecord('location', locationId);
      let landowner = locationModel.get('landOwner');
      let landownerId = landowner.get('id');
      this.get('keen').recordEvent('eventScan', {
        locationId,
        eventUrl,
        landownerId
      });
      await this.get('scriptr').request(`eventScan?locationId=${locationId}&eventUrl=${eventUrl}`);
      window.location.replace(eventUrl);
    }
  });
});