define("soofa-buzz-client/app", ["exports", "soofa-buzz-client/resolver", "ember-load-initializers", "soofa-buzz-client/config/environment", "@sentry/ember"], function (_exports, _resolver, _emberLoadInitializers, _environment, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Application
  } = Ember;
  let App;
  Ember.MODEL_FACTORY_INJECTIONS = true;
  (0, _ember.InitSentryForEmber)();
  App = Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = _exports.default = App;
});