define("soofa-buzz-client/fb-scan/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    // params

    queryParams: {
      q: {
        refreshModel: true
      }
    },
    scriptr: Ember.inject.service(),
    store: Ember.inject.service(),
    keen: Ember.inject.service(),
    async setupController(controller, model) {
      this._super(controller, model);
      let store = this.get('store');
      let rawUrlStr = controller.get('q');
      let locationId = atob(rawUrlStr);
      let locationModel = await store.findRecord('location', locationId);
      let landowner = await locationModel.get('landOwner');
      let landownerId = landowner.get('id');
      let facebookInstance = await landowner.get('facebookInstance');
      let selectedPageRaw = landowner.get('facebookSelectedPage');
      let pageId = selectedPageRaw ? selectedPageRaw.split(',')[0] : '';
      let postUrl = `https://www.facebook.com/${pageId}`;
      let contentPayload = facebookInstance.get('contentPayload');
      if (contentPayload) {
        contentPayload = JSON.parse(contentPayload);
        postUrl = contentPayload.permalinkUrl;
      }
      this.get('keen').recordEvent('facebookScan', {
        locationId,
        postUrl,
        landownerId
      });
      await this.get('scriptr').request(`fbScan?locationId=${locationId}&postUrl=${postUrl}`);
      window.location.href = postUrl;
    }
  });
});