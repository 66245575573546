define("soofa-buzz-client/components/submission-status/component", ["exports", "soofa-client-shared/mixins/has-me", "soofa-buzz-client/config/environment", "moment"], function (_exports, _hasMe, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_hasMe.default, {
    // services
    store: Ember.inject.service(),
    // properties
    answerQuestion: false,
    location: null,
    // events
    async didReceiveAttrs() {
      this._super(...arguments);
      // init
      let user = this.get('meService.me');
      // if there is a user, check to see if any orphaned submissions below to the user
      if (user) {
        // get the current page contexts' referralId
        let contextReferralId = this.get('meService.referralId');
        let contextSubmission = this.get('meService.submission');

        // if it has one, check db for orphaned submission
        if (contextReferralId && contextSubmission) {
          let userSubmissions = await user.get('submissions');
          userSubmissions.addObject(contextSubmission);
          user.save();
          contextSubmission.setProperties({
            user,
            referralId: contextReferralId
          });
          contextSubmission.save();
        }
      }

      // set ENV
      this.setProperties({
        ENV: _environment.default,
        user
      });
    },
    // computed

    // submissions: listen to any change on submissions
    submissions: Ember.computed('user.submissions', function () {
      // init
      let user = this.get('user');

      // return
      return user ? user.get('submissions').then(submissions => {
        return submissions;
      }) : null;
    }),
    // actions
    actions: {
      answer() {
        this.buttonClicked();
      },
      filterByTimespan(hours, timestamp) {
        let now = new _moment.default();
        return now.diff(timestamp, 'hours') < hours;
      }
    }
  });
});