define("soofa-buzz-client/application/route", ["exports", "soofa-client-shared/mixins/handles-auth"], function (_exports, _handlesAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_handlesAuth.default, {
    // services
    screen: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    // events
    beforeModel() {
      // this line stores the session even after page refresh
      return this.get('session').fetch().catch(function () {});
    },
    async redirect() {
      let screen = this.get('screen');
      let router = await this.get('routing').router;
      let isContentScan = router.currentRouteName !== null ? router.currentRouteName.includes('content-scan') : false;
      if (!isContentScan && screen.isDesktop) {
        this.replaceWith('not-supported');
      }
    }
  });
});