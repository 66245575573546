define("soofa-buzz-client/signup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sn3nAMeu",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"signup-page\",null,[[\"transitionTo\",\"userType\",\"welcomeMessage\",\"showFormSignUp\"],[\"location\",\"community\",\"Create an account to get notified when your responses are approved or denied\",true]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-buzz-client/signup/template.hbs"
    }
  });
});