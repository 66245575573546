define("soofa-buzz-client/services/connectivity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    name: 'connectivity',
    // services
    firebaseApp: Ember.inject.service(),
    // helpers
    setup() {
      // Get the Database service for the default app
      let db = this.get('firebaseApp').database();

      // Listen conectivity events
      window.addEventListener('online', function () {
        // docs
        // Reconnects to the server and synchronizes the offline Database state with the server state.
        // https://firebase.google.com/docs/reference/js/firebase.database.Database#go-online
        db.goOnline();
      });
      window.addEventListener('offline', function () {
        // docs
        // Disconnects from the server (all Database operations will be completed offline).
        // https://firebase.google.com/docs/reference/js/firebase.database.Database#go-offline
        db.goOffline();
      });
    }
  });
});