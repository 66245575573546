define("soofa-buzz-client/event-scan/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Oa2YjS2r",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-row layout-wrap\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex-gt-sm-100 flex-100 layout-column layout-align-center-center\"],[9],[0,\"\\n    \"],[1,[27,\"paper-progress-circular\",null,[[\"accent\",\"diameter\"],[true,128]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-buzz-client/event-scan/template.hbs"
    }
  });
});