define("soofa-buzz-client/models/slot", ["exports", "@soofa/soofa-shared-client-data/models/slot"], function (_exports, _slot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _slot.default;
    }
  });
});