define("soofa-buzz-client/instance-initializers/connectivity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    // helpers
    initialize(app) {
      let {
        container = app
      } = app;
      let connectivity = container.lookup('service:connectivity');
      connectivity.setup();
    }
  };
});