define("soofa-buzz-client/password/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    // events
    beforeModel() {
      if (this.get('session').get('isAuthenticated')) {
        return this.transitionTo('dashboard');
      }
    }
  });
});