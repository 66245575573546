define("soofa-buzz-client/helpers/format-phonenumber", ["exports", "ember-libphonenumber-js/helpers/format-phonenumber"], function (_exports, _formatPhonenumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _formatPhonenumber.default;
    }
  });
  Object.defineProperty(_exports, "formatPhonenumber", {
    enumerable: true,
    get: function () {
      return _formatPhonenumber.formatPhonenumber;
    }
  });
});