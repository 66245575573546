define("soofa-buzz-client/initializers/visionect-watchdog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global okular */
  var _default = _exports.default = {
    initialize() {
      /* eslint-disable new-cap */
      if (typeof okular == 'object' && typeof okular.ReloadTimeout == 'function') {
        setInterval(function () {
          okular.ReloadTimeout(60);
        }, 20000);
      }
    }
  };
});