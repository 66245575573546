define("soofa-buzz-client/helpers/contains-substring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.containsSubstring = containsSubstring;
  _exports.default = void 0;
  const {
    Helper: {
      helper
    }
  } = Ember;
  function containsSubstring(params) {
    let [needle, haystack] = params;
    return haystack.includes(needle);
  }
  var _default = _exports.default = helper(containsSubstring);
});