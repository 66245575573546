define("soofa-buzz-client/location/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    // services
    screen: Ember.inject.service(),
    // events
    redirect() {
      let screen = this.get('screen');
      if (screen.isDesktop) {
        this.replaceWith('not-supported');
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        step: 1
      });
    },
    // actions
    actions: {
      handleLocationSuccess(location) {
        this.transitionTo('location.confirm', location);
      },
      handleExplore(url) {
        // go to url
        window.location.href = url;
      },
      restartLocationProcess(context) {
        context.set('step', 1);
      }
    }
  });
});