define("soofa-buzz-client/helpers/weather-icon-converter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.weatherIconConverter = weatherIconConverter;
  const {
    Helper: {
      helper
    }
  } = Ember;
  function weatherIconConverter(icon) {
    // mapping logic based on Open Weather Docs: https://openweathermap.org/weather-conditions
    if (icon == 'clear-day') {
      icon = 'weather-sunny';
    } else if (icon == 'clear-night') {
      icon = 'weather-night';
    } else if (icon == 'rain') {
      icon = 'weather-rainy';
    } else if (icon == 'snow') {
      icon = 'weather-snowy';
    } else if (icon == 'sleet') {
      icon = 'weather-snowy-rainy';
    } else if (icon == 'wind') {
      icon = 'weather-windy';
    } else if (icon == 'fog') {
      icon = 'weather-fog';
    } else if (icon == 'cloudy') {
      icon = 'weather-cloudy';
    } else if (icon == 'partly-cloudy-day') {
      icon = 'weather-partly-cloudy';
    } else if (icon == 'partly-cloudy-night') {
      icon = 'weather-night-partly-cloudy';
    } else {
      icon = 'weather-partly-cloudy';
    }
    // return mapped ember-mdi icon
    return icon;
  }
  var _default = _exports.default = helper(weatherIconConverter);
});