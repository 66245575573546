define("soofa-buzz-client/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hy0Gjl61",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"toaster-helper\"],false],[0,\"\\n\\n\"],[7,\"header\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"noNav\"]]],null,{\"statements\":[[4,\"paper-toolbar\",null,[[\"class\"],[[27,\"if\",[[23,[\"isStaging\"]],\"accent-blue\"],null]]],{\"statements\":[[4,\"paper-toolbar-tools\",null,null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"hide-md hide-lg hide-xl flex-10\"],[9],[0,\"\\n\"],[0,\"        \"],[10],[0,\"\\n\\n\"],[4,\"link-to\",[\"index\"],null,{\"statements\":[[4,\"if\",[[23,[\"isStaging\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"cloudinary-image\",[\"soofa-buzz/soofa-buzz-logo-white\"],[[\"class\",\"alt\"],[\"logo\",\"logo\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[27,\"cloudinary-image\",[\"soofa-buzz/soofa-buzz-logo-red\"],[[\"class\",\"alt\"],[\"logo\",\"logo\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"        \"],[7,\"div\"],[11,\"class\",\"hide-md hide-lg hide-xl flex-10\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"main\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-buzz-client/templates/application.hbs"
    }
  });
});