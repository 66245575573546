define("soofa-buzz-client/helpers/parse-phonenumber", ["exports", "ember-libphonenumber-js/helpers/parse-phonenumber"], function (_exports, _parsePhonenumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _parsePhonenumber.default;
    }
  });
  Object.defineProperty(_exports, "parsePhonenumber", {
    enumerable: true,
    get: function () {
      return _parsePhonenumber.parsePhonenumber;
    }
  });
});